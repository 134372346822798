/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import cn from "classnames";
import Cookies from "js-cookie";
import Imgix from "react-imgix";
import BasicTable from "../../../Table/BasicTable";
import BasicTableBody from "../../../Table/BasicTableBody";
import BasicTableHeader from "../../../Table/BasicTableHeader";
import BasicTableRow from "../../../Table/BasicTableRow";
import styles from "./Account.module.scss";
import {
  getUserInfo,
  getUserInstagramAccounts,
  patchUserInfo,
  PatchUserProps,
} from "../../../../store/user/actions";
import { useAppDispatch } from "../../../../store";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
  selectUserInfoLoading,
} from "../../../../store/user/selectors";
import { User } from "../../../../types/User";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import ForwardArrow from "../Auctions/AuctionCreation/internal/ForwardArrow";
import { getStoreById, StoreProps } from "../../../../store/stores/actions";
import {
  isStoreLoading,
  selectStore,
} from "../../../../store/stores/selectors";
import LoadingSmall from "../../../Loading/LoadingSmall";
import { clearUserInfo } from "../../../../store/user/reducer";
import CloseButton from "../Auctions/AuctionCreation/internal/CloseButton";
import RoundedButton from "../../../Buttons/RoundedButton";
import { resetAuctionModalState } from "../../../../store/auctionModal/reducer";
import { resetAuctions } from "../../../../store/auctions/reducer";
import { resetStore } from "../../../../store/stores/reducer";
import { LocationTypes } from "../../../Onboarding/ConnectBidddy";
import { Market } from "../../../../types/Market";

const PlaceholderImage =
  "https://imgix.cosmicjs.com/726a17f0-52d5-11ec-9aff-3d50541531a0-Placeholder.png";

const Account = () => {
  const dispatch = useAppDispatch();
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const currentStore = useSelector((state) => selectStore(state)) as StoreProps;
  const loading = useSelector(
    (state) => isStoreLoading(state) || selectUserInfoLoading(state)
  );
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const [displayNameModal, setDisplayNameModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getUserInstagramAccounts());
  }, []);

  useEffect(() => {
    if (userInfo && !requestingUserInfo) {
      dispatch(getStoreById(userInfo.data.last_store_id));
      setFirstName(userInfo.data.first_name);
      setLastName(userInfo.data.last_name || "");
    }
  }, [userInfo]);

  const handleLogout = () => {
    Cookies.remove("access");
    Cookies.remove("refresh");
    Cookies.remove("account_id");
    Cookies.remove("account_type");
    Cookies.remove("auth_failure");
    Cookies.remove("tasksCompletedAt");
    dispatch(resetAuctionModalState());
    dispatch(resetAuctions());
    dispatch(clearUserInfo());
    dispatch(resetStore());
    navigate("/signin/");
  };

  const handleUpdateNames = () => {
    const updatedProps = {
      user: {
        first_name: firstName,
        last_name: lastName,
      },
    } as PatchUserProps;
    dispatch(patchUserInfo(updatedProps));
    setDisplayNameModal(false);
  };

  const checkAccounts = () => {
    if (loading) {
      return;
    }
    if (availableMarkets.length > 0) {
      navigate("/dashboard/getting-started/ready-to-roll/", {
        state: {
          location: LocationTypes.Account,
          navigationPath: LocationTypes.Account,
        },
      });
    } else {
      navigate("/dashboard/getting-started/connect-instagram/", {
        state: {
          location: LocationTypes.Account,
          navigationPath: LocationTypes.Account,
        },
      });
    }
  };

  return (
    <>
      <div className={styles.accountContainer}>
        <div className={styles.imageTempPlaceholder}>
          <Imgix
            src={`${PlaceholderImage}?auto=format`}
            height={138}
            width={138}
            htmlAttributes={{
              alt: "Placeholder Image",
              src: `${PlaceholderImage}?auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
          <h2>
            {userInfo && userInfo.data.first_name}{" "}
            {userInfo && userInfo.data.last_name ? userInfo.data.last_name : ""}
          </h2>
        </div>
        <div className={styles.infoContainer}>
          <BasicTable>
            <BasicTableHeader>
              <BasicTableRow>
                <td colSpan={3}>Profile</td>
              </BasicTableRow>
            </BasicTableHeader>
            <BasicTableBody>
              <BasicTableRow onClick={() => setDisplayNameModal(true)}>
                <td>Name</td>
                <td>
                  {userInfo && userInfo.data.first_name}{" "}
                  {userInfo && userInfo.data.last_name
                    ? userInfo.data.last_name
                    : ""}
                </td>
                <td>
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
              <BasicTableRow>
                <td>Email</td>
                <td>{userInfo && userInfo.data.email}</td>
                <td>
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
            </BasicTableBody>
          </BasicTable>
          <BasicTable>
            <BasicTableHeader>
              <BasicTableRow>
                <td colSpan={3}>Social Accounts</td>
              </BasicTableRow>
            </BasicTableHeader>
            <BasicTableBody>
              <BasicTableRow onClick={() => checkAccounts()}>
                <td>Instagram</td>
                <td />
                <td>
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
            </BasicTableBody>
          </BasicTable>
          <BasicTable>
            <BasicTableHeader>
              <BasicTableRow>
                <td colSpan={3}>General</td>
              </BasicTableRow>
            </BasicTableHeader>
            <BasicTableBody>
              <BasicTableRow
                onClick={() =>
                  navigate("/dashboard/account/notification-settings/")
                }
              >
                <td>
                  <p>Notification Settings</p>
                </td>
                <td />
                <td>
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
              <BasicTableRow
                onClick={() => navigate("/dashboard/account/auction-settings/")}
              >
                <td>
                  <p>Auction Settings</p>
                </td>
                <td />
                <td>
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
              <BasicTableRow onClick={() => navigate("/learn/how-it-works")}>
                <td>
                  <p>How It Works</p>
                </td>
                <td />
                <td>
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
              <BasicTableRow onClick={() => navigate("/terms-of-service")}>
                <td>Terms &amp; Conditions</td>
                <td />
                <td>
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
              <BasicTableRow onClick={() => navigate("/privacy-policy")}>
                <td>Privacy Policy</td>
                <td />
                <td>
                  <ForwardArrow onClick={() => {}} />
                </td>
              </BasicTableRow>
            </BasicTableBody>
          </BasicTable>
          <button type="button" onClick={() => handleLogout()}>
            Log Out
          </button>
        </div>
      </div>
      {loading && (
        <div
          className={cn([
            "fixed inset-0 flex justify-center h-full items-center z-50",
            styles.loadingContainer,
          ])}
        >
          <LoadingSmall color="black" />
        </div>
      )}
      {displayNameModal && (
        <div className={styles.editNameModal}>
          <div className={styles.modalContainer}>
            <CloseButton onClick={() => setDisplayNameModal(false)} />
            <div className={styles.nameContainer}>
              <label>First Name</label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className={styles.nameContainer}>
              <label>Last Name</label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <RoundedButton onClick={() => handleUpdateNames()}>
              UPDATE
            </RoundedButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Account;
