import React from "react";
import { AuthProvider } from "../../../components/Auth/auth";
import DashboardLayout from "../../../components/DashboardLayout";
import Account from "../../../components/PageComponents/Dashboard/Account/Account";

const AccountPage = () => (
  <AuthProvider>
    <DashboardLayout title="Bidddy | Your Profile" hideFooter>
      <Account />
    </DashboardLayout>
  </AuthProvider>
);

export default AccountPage;
